// 菜单管理
<template>
  <div class="menu">
    <div class="screening">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="筛选框" name="1">
          <div class="screening-out">
            <el-form :inline="true" class="demo-form-inline">
              <el-row>
                <el-col :span="6">
                  <el-form-item label="名称">
                    <el-input v-model="searchValue" placeholder="请输入需要查询的菜单名称" size="mini"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <!-- <el-form-item label="创建时间">
                                        <el-date-picker
                                            v-model="valueTime"
                                            type="datetimerange"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            size="mini"
                                            :default-time="defaultTime">
                                        </el-date-picker>
                                    </el-form-item> -->
                </el-col>
                <el-col :span="6">
                  <!-- <el-form-item> -->
                  <div class="title-buttom">
                    <el-button class="cancel" size="mini" @click="reset" round>重置</el-button>
                    <el-button type="primary" size="mini" class="confirmAdd" @click="onSubmit" round>查询</el-button>
                  </div>
                  <!-- </el-form-item> -->
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <el-row>
      <el-col :span="12">
        <div class="title-name">
          菜单列表
        </div>
      </el-col>
      <el-col :span="12">
        <div class="title-buttom">
          <el-tooltip content="新增菜单" placement="top" effect="light">
            <el-button class="editor" size="mini" icon="el-icon-plus" @click="addMenu" circle></el-button>
          </el-tooltip>
          <el-tooltip content="刷新列表" placement="top" effect="light">
            <el-button class="editor" size="mini" icon="el-icon-refresh-right" @click="reset" circle></el-button>
          </el-tooltip>
          <!-- <el-button class="deletetable" size="mini" icon="el-icon-delete" @click="deleteData" round>删除</el-button> -->
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div>
          <el-table class="eltable" v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" :header-cell-style="{background:'#e0e5ff',color:'#24252F'}" row-key="id" default-expand-all :tree-props="{children: 'children', hasChildren: 'hasChildren'}" @selection-change="handleSelectionChange">
            <el-table-column label="菜单名称">
              <template #default="scope">{{ scope.row.name == null?'-': scope.row.name}}</template>
            </el-table-column>
            <el-table-column label="图标" width="80">
              <template #default="scope">
                <i class="iconfont img-icon" :class="scope.row.icon" />
              </template>
            </el-table-column>
            <el-table-column label="排序" width="80">
              <template #default="scope">{{ scope.row.sort == null?'-': scope.row.sort}}</template>
            </el-table-column>
            <el-table-column label="组件地址">
              <template #default="scope">{{ scope.row.route == null?'-': scope.row.route}}</template>
            </el-table-column>
            <el-table-column label="路由">
              <template #default="scope">{{ scope.row.url == null?'-': scope.row.url}}</template>
            </el-table-column>
            <el-table-column label="创建时间">
              <template #default="scope">{{ scope.row.createTime == null?'-': scope.row.createTime}}</template>
            </el-table-column>
            <el-table-column label="所属模块" width="100">
              <template #default="scope">
                <el-tag>{{ $filter.appIdToModule(scope.row.appId,ppJson) }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="显示/不显示" align="center" width="100">
              <template #default="scope">
                <!-- <a-switch size="small" v-model="scope.row.status" :checkedValue="0" :unCheckedValue="1" @click="onSwitch(scope.row, scope.row.status)" /> -->
                <div style="height:25px;" @click="onSwitch(scope.row, scope.row.status)">
                                    <el-switch
                                    style="display: block"
                                    v-model="scope.row.status"
                                    :active-value = '0'
                                    :inactive-value = '1'
                                    active-color="#637DFF"
                                    inactive-color="#bcb9b8">
                                    </el-switch>
                                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template #default="scope">
                <el-tooltip content="编辑菜单" placement="top" effect="light">
                  <el-button class="editor" size="mini" icon="el-icon-edit" @click="modify(scope.row.id)" round></el-button>
                </el-tooltip>
                <el-tooltip content="删除菜单" placement="top" effect="light">
                  <el-button class="deletetable" size="mini" icon="el-icon-delete" @click="confirmEvent(scope.row.id)" round></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- <el-row>
            <el-col :span="16"></el-col>
            <el-col :span="8">
                <div>
                   <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="40">
                    </el-pagination>
                </div>
            </el-col>
        </el-row> -->
    <!-- 弹窗 -->
    <div class="popup-view">
      <el-form label-position="top" label-width="100px" :model="formMenu" :rules="rules" ref="formMenu">
        <el-dialog :title="popupTitle == 0 ? '新增菜单' : '修改菜单'" v-model="centerDialogVisible" width="50%" :destroy-on-close="true" @close='closeDialog'>
          <!-- 图标展示 -->
          <div class="popup-view">
            <el-dialog width="30%" title="请选择图标" v-model="innerVisible" :destroy-on-close="true" append-to-body>
              <div class="showIcon">
                <div v-for="(icons,i) in iconList" :key="i" class="out-icon" :class="isIconSelected == i ?'confirmAdd':''" @click="selectedIcon(i,icons.icon)">
                  <div class="icon-box">
                    <i class="iconfont img-icon" :class="icons.icon" />
                  </div>
                  <div class="icon-name">{{ icons.name }}</div>
                </div>
              </div>
              <template #footer>
                <el-form-item>
                  <el-button class="cancel" plain @click="innerVisible = false" round>取消</el-button>
                  <el-button class="confirmAdd" @click="seleIcon" round>确认</el-button>
                </el-form-item>
              </template>
            </el-dialog>
          </div>
          <div class="center-body">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="菜单名称：" prop="name">
                  <el-input v-model="formMenu.name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="父级菜单：" prop="parentId">
                  <div class="elCascader">
                    <el-cascader v-model="value" :options="treeList" @change="handleChange" :props="{checkStrictly: true,value:'id',label:'name',children: 'children'}">
                    </el-cascader>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="排序：">
                  <el-input-number size="medium" v-model="num"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="模块：">
                  <el-select v-model="optionsValue" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="组件地址：">
                  <el-input v-model="formMenu.route"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="路由：">
                  <el-input v-model="formMenu.url"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="菜单图标：">
                  <el-input v-model="formMenu.icon">
                    <template #append>
                      <el-button @click="innerVisible = true" icon="el-icon-s-shop"></el-button>
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="菜单备注：">
                  <el-input type="textarea" placeholder="请输入内容" :autosize="{ minRows: 4, maxRows: 6}" v-model="formMenu.remark" maxlength="30" show-word-limit>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <template #footer>
            <el-form-item>
              <el-button class="cancel" plain @click="resetForm('formMenu')" round>取消</el-button>
              <el-button class="confirmAdd" @click="submitForm('formMenu')" round>{{ popupTitle == 0 ? '新增菜单' : '修改菜单' }}</el-button>
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
  </div>
</template>

<script>
import menu from '@/api/system/menu'
import app from '@/api/web/app'
import { IconFont } from '@/utils/iconfont'
import { iconfontList } from '@/public/js/iconfontList'
export default {
  components: {
    IconFont,
  },
  data() {
    return {
      tableHeight: '',
      searchValue: '', //搜索值
      currentPage: 1, //分页
      multipleSelection: [], //选中集合
      num: 1, //排序
      value: [], //父级选中
      valueTime: '', //选中时间段
      defaultTime: [
        new Date(2000, 1, 1, 12, 0, 0),
        new Date(2000, 2, 1, 8, 0, 0),
      ], //默认时间 '12:00:00', '08:00:00'
      activeNames: [], //折叠面板
      tableData: [], //菜单表
      treeList: [], //全菜单
      // loding 状态启用
      loading: true,
      centerDialogVisible: false, //弹窗启用
      innerVisible: false, //图标弹窗启用
      popupTitle: 0, //弹窗标题
      iconList: [], //图标列表
      isIconSelected: '', //当前选中的图标标签index
      selectedIcons: '', //当前选中的图标标签编号
      options: [], //模块列表
      optionsValue: '',
      formMenu: {
        //菜单表
        name: '', //菜单名称
        icon: 'default', //菜单图标
        parentId: '', //父菜单id
        route: '/default', //路由
        sort: '', //排序
        url: '', //url
        appId: '', //模块
        remark: '', //菜单备注
      },
      rules: {
        name: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
          {
            min: 2,
            max: 15,
            message: '长度在 2 到 15 个字符',
            trigger: 'blur',
          },
        ],
        parentId: [
          { required: true, message: '请选择父级菜单', trigger: 'blur' },
        ],
      },
      ppJson: {},
    }
  },
  created() {
    this.tableHeight = this.$store.state.tableHeight
    this.getLowList()
  },
  watch: {},
  mounted() {
    // this.getLowList()
    this.getMenuList(true)
    this.iconList = iconfontList
  },
  methods: {
    getLowList() {
      app.appQuery({}).then((res) => {
        this.options = res.data
        let newJson = {}
        res.data.forEach((item) => {
          newJson[item.id] = item.name
        })
        this.ppJson = newJson
      })
    },
    // 查询
    onSubmit() {
      menu.tree({}).then((res) => {
        let mallList = this.flatten(res.data[0].children)
        let _this = this
        mallList = mallList.filter(function (t) {
          return t.name.search(_this.searchValue) > -1 //search()或者indexOf()
        })
        this.tableData = mallList
        this.loading = false
      })
    },
    // 重置
    reset() {
      this.searchValue = ''
      this.getMenuList(true)
    },
    // 获取菜单目录
    getMenuList(type) {
      this.loading = type
      menu.tree({}).then((res) => {
        this.tableData = res.data[0].children
        this.treeList = res.data
        this.loading = false
      })
    },
    // 将树状表扁平化
    flatten(arr) {
      var res = []
      arr.map((item) => {
        if (Array.isArray(item.children) && item.children.length > 0) {
          res.push(item)
          res = res.concat(this.flatten(item.children))
        } else {
          res.push(item)
        }
      })
      return res
    },
    // 判断是回显效果
    judgeShow(type) {
      this.loading = true
      if (this.searchValue != '') {
        this.onSubmit()
      } else {
        this.getMenuList(type)
      }
    },
    // 选中图标标签
    selectedIcon(i, icons) {
      this.isIconSelected = i
      this.selectedIcons = icons
    },
    // 确定选择
    seleIcon() {
      this.formMenu.icon = this.selectedIcons
      this.innerVisible = false
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    },
    // 折叠面板
    handleChange(val) {
      console.log(val)
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 批量删除
    deleteData() {
      console.log(this.multipleSelection)
    },
    // 修改显示状态
    onSwitch(row, status) {
      menu
        .editTreeData({
          id: row.id,
          parentId: row.parentId,
          status: status == 1 ? 1 : 0,
        })
        .then((res) => {
          this.judgeShow(false)
          this.$notify({
            dangerouslyUseHTMLString: true,
            message:
              '<p>菜单显示状态修改成功！</p><p style="font-style: normal;color: #b6b6b9;">导航栏需要重新登陆才能生效。</p>',
            type: 'success',
          })
          this.centerDialogVisible = false
        })
    },
    // 新增
    addMenu() {
      ;(this.formMenu = {
        name: '',
        icon: 'default',
        parentId: '',
        route: '/default',
        sort: '',
        url: '',
        remark: '',
      }),
        (this.value = [])
      this.popupTitle = 0
      this.centerDialogVisible = true
    },
    // 修改
    modify(id) {
      // 获取当前行详情
      menu
        .getTreeData({
          id: id,
        })
        .then((res) => {
          this.formMenu = res.data
          this.num = res.data.sort
          let newPid = []
          let newPath = res.data.path.split(',')
          newPath.forEach((item, i) => {
            if (item != '') {
              newPid.push(item)
            }
          })
          this.value = newPid
          this.optionsValue = res.data.appId + ''
          this.popupTitle = 1
          this.centerDialogVisible = true
        })
    },
    // 删除确认
    confirmEvent(id) {
      this.$confirm('将删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          menu
            .deleteTree({
              id: id,
            })
            .then((res) => {
              this.judgeShow(true)
              this.$notify({
                title: '成功',
                dangerouslyUseHTMLString: true,
                message:
                  '<p>菜单删除成功！</p><p style="font-style: normal;color: #b6b6b9;">导航栏需要重新登陆才能生效。</p>',
                type: 'success',
              })
            })
        })
        .catch(() => {
          this.$notify.info({
            title: '通知',
            message: '已取消删除!',
          })
        })
    },
    // 取消
    cancelEvent() {
      console.log('cancel!')
    },
    // 表单提交
    submitForm(formName) {
      this.formMenu.appId = this.optionsValue
      this.formMenu.parentId = this.value[this.value.length - 1]
      this.formMenu.sort = this.num
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.popupTitle == 0) {
            menu
              .addTree({
                ...this.formMenu,
              })
              .then((res) => {
                this.judgeShow(true)
                this.$notify({
                  title: '成功',
                  dangerouslyUseHTMLString: true,
                  message:
                    '<p>菜单添加成功！</p><p style="font-style: normal;color: #b6b6b9;">导航栏需要重新登陆才能生效。</p>',
                  type: 'success',
                })
                this.centerDialogVisible = false
              })
          } else {
            menu
              .editTreeData({
                ...this.formMenu,
              })
              .then((res) => {
                this.judgeShow(true)
                this.$notify({
                  title: '成功',
                  dangerouslyUseHTMLString: true,
                  message:
                    '<p>菜单修改成功！</p><p style="font-style: normal;color: #b6b6b9;">导航栏需要重新登陆才能生效。</p>',
                  type: 'success',
                })
                this.centerDialogVisible = false
              })
          }
        } else {
          // console.log('error submit!!');
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.centerDialogVisible = false
    },
    closeDialog() {
      this.$refs.formMenu.resetFields()
      this.centerDialogVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.menu {
  height: 100%;
  overflow-y: overlay;
  padding-right: 18px;
  .title-name {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }
  .top-first {
    .search-box {
      width: 18.75rem;
    }
  }
}
.screening {
  margin-bottom: 0.9375rem;
}
.el-row {
  margin-bottom: 0.9375rem;
  &:last-child {
    margin-bottom: 0;
  }
}
.space-tile {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.editor {
  color: #0170ed;
  background: #e3f0ff;
}
.center-body {
  margin: 0 1.875rem;
}
.deletetable {
  color: #ff845b;
  background: #ffe4db;
}
.deletetable-other {
  margin-right: 1.25rem;
}
.confirmAdd {
  background: #637dff;
  color: #fff;
}
.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}
.screening-out {
  margin: 0 0.9375rem;
}
.showIcon {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  overflow-y: overlay;
  height: 28.125rem;
  .out-icon {
    border-radius: 0.5rem;
    .icon-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 3rem;
    }
    .icon-name {
      text-align: center;
      width: 4rem;
      height: 2.5rem;
      line-height: 2.5rem;
    }
  }
}
.out-icon:hover {
  background: #e3f0ff;
  color: #0170ed;
}
</style>

<style lang="scss">
.menu .el-collapse-item__content {
  padding-bottom: 0 !important;
}
// .menu .el-button {
//     // border-color: #FFFFFF !important;
// }
.eltable {
  overflow-y: overlay;
}
</style>